import styled from "styled-components";

export default styled.div`
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
  align-items: center;
  background-color: rgba(242, 242, 242, 0.8);
  color: rgb(68, 68, 68);
  flex-direction: column;
  padding: 48px;
  border-radius: 24px;
`;
