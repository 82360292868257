import styled from "styled-components";
import { MUTED_TEXT } from "../colors";

export default styled.div`
  margin-top: 5px;
  color: ${MUTED_TEXT};
  font-size: 16px;
  font-style: italic;
  max-width: 400px;
`;
