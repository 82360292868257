import styled from "styled-components";

export default styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    margin: 0 10px;
  }
`;
