import React from "react";
import { fromJS } from "immutable";
import { navigate } from "gatsby";
import { gql, useQuery, useMutation } from "@apollo/client";
import Dialog from "../components/Dialog";
import Form from "../components/Form";
import FormField from "../components/FormField";
import Label from "../components/Label";
import Input from "../components/Input";
import FormError from "../components/FormError";
import FormHint from "../components/FormHint";
import { Formik } from "formik";
import ButtonRow from "../components/ButtonRow";
import PrimaryButton from "../components/buttons/PrimaryButton";
import PageWrapper from "../components/PageWrapper";
import Bugsnag from "@bugsnag/js";
import DarkPageHeading from "../components/DarkPageHeading";

const ALL_QUIZZES = gql`
  query {
    allQuizzes {
      publicId
    }
  }
`;

const HOST_QUIZ = gql`
  mutation HostQuiz($publicQuizId: String!) {
    hostQuiz(publicQuizId: $publicQuizId) {
      quiz {
        id
        name
      }
    }
  }
`;

export default function Home() {
  const { loading, data } = useQuery(ALL_QUIZZES);

  const [hostQuiz] = useMutation(HOST_QUIZ);

  const allQuizzes = data ? fromJS(data.allQuizzes) : null;

  const validate = (values) => {
    const errors = {};
    if (!values.meetingId) {
      errors.meetingId = "You must enter a Quiz ID";
    }
    return errors;
  };

  const handleHost = async (
    values,
    { setStatus, setErrors, setSubmitting }
  ) => {
    try {
      await hostQuiz({
        variables: { publicQuizId: values.meetingId },
      });
      await navigate("/play-quiz");
      // next line is a hack to prevent loading indicator from disappearing before page switch
      await new Promise((resolve) => setTimeout(resolve, 5000));
    } catch (error) {
      Bugsnag.notify(error);

      if (
        error.graphQLErrors[0].message === "Quiz matching query does not exist."
      ) {
        setErrors({ meetingId: "There is no quiz with that ID" });
        // setStatus("There is no quiz with that ID");
      } else {
        setStatus("Unknown error, try again");
      }
      setSubmitting(false);
    }
  };

  return (
    <PageWrapper>
      <Dialog>
        <DarkPageHeading>Host Quiz</DarkPageHeading>
        <Formik
          initialValues={{ meetingId: "" }}
          validate={validate}
          onSubmit={handleHost}
        >
          {({
            values,
            errors,
            status,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <FormField>
                <Label>Enter Quiz ID</Label>
                <Input
                  name="meetingId"
                  type="text"
                  value={values.meetingId}
                  autoFocus
                  autoComplete="off"
                  onChange={handleChange}
                />
                {errors.meetingId && <FormError>{errors.meetingId}</FormError>}
                {status && <FormError>{status}</FormError>}
                {loading && (
                  <FormHint>Getting a list of valid Quiz IDs...</FormHint>
                )}
                {allQuizzes && (
                  <FormHint>
                    Valid ids:{" "}
                    {allQuizzes
                      .map((quiz) => `${quiz.get("publicId")}`)
                      .join(", ")}
                  </FormHint>
                )}
              </FormField>

              <ButtonRow>
                <PrimaryButton
                  type="submit"
                  size="large"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Joining" : "Join"}
                </PrimaryButton>
              </ButtonRow>
            </Form>
          )}
        </Formik>
      </Dialog>
    </PageWrapper>
  );
}
